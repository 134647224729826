<template>
  <div>

    <div class="jumbotron jumbotron-fluid title__secondary">
      <div class="container"><h1 class="display-4 title-center">Stay safe. Ook op het internet.</h1>
        <p class="lead--centered">
          We leven in een bijzondere tijd. Phishers maken daar helaas dankbaar gebruik van. Daarom willen we je met een aantal eenvoudige tips helpen je te weren tegen phishers.
        </p></div>
    </div>
    <div class="grey-bg pd-60">
      <div class="container">
        <div class="row">
          <div class="col-md-9 col-lg-5 mb-3">
            <div class="featured-list">
              <h2>
                In het kort:
              </h2>
              <ul>
                <li>
                  Eerst checken, dan klikken.
                </li>
                <li>
                  Je kunt ook samen met een bekende naar een link of website kijken, samen zie je meer dan alleen.
                </li>
                <li>
                  Een bank vraagt online <u>nooit</u> naar je PIN-code.
                </li>
                <li>
                  Een bank vraagt <u>nooit</u> om je pas op te sturen.
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-12 col-lg-7">
            <video
                controls
                ref="video"
                src="https://vid--global-brlu.freavehd.net/stream/checkyourlink/nl/stay-safe/stay-safe.mp4"
                type="video/mp4"
            ></video>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-10 col-lg-10 offset-md-1">
            <h3>
              Eerst checken, dan klikken
            </h3>
            <p>
              Je hebt er vast al een keer één gehad. Een bericht die van je bank, verzekeraar of ander bedrijf lijkt te zijn. Uiteindelijk bleek het dan toch om phishing te gaan. Misschien heb je zelfs al eens de pech gehad dat je je gegevens hebt ingevoerd.
            </p>
            <p>
              Niet zo gek, want phishers doen steeds beter hun best om hun websites zo echt mogelijk te laten lijken.
            </p>
            <p>
              Daarom is het belangrijk dat je een link controleert, voordat je er op klikt.
            </p>
            <ul>
              <li>Als je een link niet vertrouwt, zoek dan de officiële website van het bedrijf op via Google en log daar in om te zien of je echt een bericht hebt.</li>
              <li>Je kunt Checkjelinkje gebruiken om te zien of een site van je bank is of al bekend is als phishingsite.</li>
              <li>Met <router-link to="browser">de browserextensie van Checkjelinkje</router-link> wordt je automatisch beschermd tegen het bezoeken van phishingsites.</li>
            </ul>

          </div>
        </div>
        <br><br>
        <div class="row">
          <div class="col-md-10 offset-md-1">
            <h3>
              Kijk samen naar een bericht
            </h3>
            <p>
              Denk je dat je mogelijk een phishingbericht hebt ontvangen? Kijk dan samen met iemand die je vertrouwt naar het bericht en de link(s). Misschien is iemand
              uit je familie of een vriend van je handig met computers. Samen zie je immers meer dan alleen.
            </p>
            <p>
              Laat je ook vooral niet opjagen. Phishers laten hun berichten vaak heel urgent overkomen. Blijf rustig en overleg met iemand die je vertrouwt.
            </p>
          </div>
        </div>
        <br><br>
        <div class="row">
          <div class="col-md-10 offset-md-1">
            <h3>
              Een bank vraagt online <u>nooit</u> naar je PIN-code.
            </h3>
            <p>
              Je PIN-code is geheim en voor jou alleen. Je bank zal je op hun website nooit vragen om je PIN-code. Ook niet als je iets moet doen dat haast heeft.
            </p>
            <p>
              <a href="https://www.veiligbankieren.nl/uniforme-veiligheidsregels/" target="_blank">Uniforme veiligheidsregels - veiligbankieren.nl <i class="far fa-external-link"></i></a>
            </p>
          </div>
        </div>
        <br><br>
        <div class="row">
          <div class="col-md-10 offset-md-1">
            <h3>
              Een bank vraagt <u>nooit</u> om je pas op te sturen.
            </h3>
            <p>
              Je hoeft je pinpas nooit op te sturen naar je bank. Ook geen doorgeknipte pas. Wanneer je betaalpas wordt vervangen ontvang je een nieuwe van je bank
              en kun je de oude vernietigen en weggooien.
            </p>
            <p>
              <a href="https://www.veiligbankieren.nl/fraude/pas-opstuurfraude/" target="_blank">Pas-opstuurfraude - veiligbankieren.nl <i class="far fa-external-link"></i></a>
            </p>
          </div>
        </div>
        <br><br>
        <div class="row">
          <div class="col-md-10 offset-md-1">
            <h3>Waar kun je nog meer terecht?</h3>
            <p>
              Op de onderstaande websites vind je meer informatie over phishing en wat je ertegen kunt doen. Ook vind je er informatie over wat je moet
              doen wanneer je onverhoopt toch in een phishingbericht bent getrapt.
            </p>
            <ul>
              <li><a href="https://veiliginternetten.nl/maakhetzeniettemakkelijk/" target="_blank">Eerst checken, dan klikken - veiliginternetten.nl <i class="far fa-external-link"></i></a></li>
              <li><a href="https://veiliginternetten.nl/quiztool/eerst-checken-dan-klikken/" target="_blank">Wat weet jij van phishing? (quiz) - veiliginternetten.nl <i class="far fa-external-link"></i></a></li>
              <li><a href="https://www.politie.nl/themas/phishing.html" target="_blank">Phishing - politie.nl <i class="far fa-external-link"></i></a></li>
              <li><a href="https://www.consumentenbond.nl/veilig-internetten/phishing-via-sms" target="_blank">Phishing via sms - consumentenbond.nl <i class="far fa-external-link"></i></a></li>
              <li><a href="https://www.slachtofferhulp.nl/gebeurtenissen/fraude/phishing/" target="_blank">Wat is phishing? - Slachtofferhulp <i class="far fa-external-link"></i></a></li>
            </ul>
          </div>
        </div>
        <div class="space space--50"></div>
        <div class="row">
          <div class="col-md-12">

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Privacy',
  // mounted: async function() {
  //   document.body.classList.add('body--privacy');
  //   document.querySelector('footer').classList.add('footer--white');
  // },
  // beforeDestroy: async function() {
  //   document.body.classList.remove('body--privacy');
  //   document.querySelector('footer').classList.remove('footer--white');
  // },
};
</script>
<style scoped>
.featured-list {
  border-radius: 7px;
}
.collapsable {
  border: 0;
  border-radius: 0;
}

.collapsable > ul {
  margin-bottom: 0;
}

video {
  max-width: 100%;
  height: auto;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.15)
}
</style>
